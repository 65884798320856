export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは10月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n今年も残すところわずか。12月はクリスマスや忘年会などイベント事が多く、体重が増えやすい時期です。いち早く身体の変化に気が付くために、こまめな体重計測を心がけましょう。毎日決められた時間に計ることがおすすめです。\n\n",
      "down": "生活習慣スコアは10月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n今年も残すところわずか。12月はクリスマスや忘年会などイベント事が多く、体重が増えやすい時期です。いち早く身体の変化に気が付くために、こまめな体重計測を心がけましょう。毎日決められた時間に計ることがおすすめです。\n\n",
      "equal100": "生活習慣スコアは10月に引き続き%満点%です。素晴らしい！<br>\n今年も残すところわずか。12月はクリスマスや忘年会などイベント事が多く、体重が増えやすい時期です。いち早く身体の変化に気が付くために、こまめな体重計測を心がけましょう。毎日決められた時間に計ることがおすすめです。\n\n",
      "equal": "生活習慣スコアは10月と同じ点数です。<br>\n今年も残すところわずか。12月はクリスマスや忘年会などイベント事が多く、体重が増えやすい時期です。いち早く身体の変化に気が付くために、こまめな体重計測を心がけましょう。毎日決められた時間に計ることがおすすめです。\n\n"
    },
    "steps": {
      "up": "歩数スコアは10月より%d点上がりました。この調子で頑張りましょう！<br>\n歩くスピードを意識したことはありますか？同じ歩数でも速く歩く方が消費カロリーは多くなります。普段から、速く歩くことを意識してみてください。詳しくは<a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n",
      "down": "歩数スコアは10月より%d点下がりました。<br>\n歩くスピードを意識したことはありますか？同じ歩数でも速く歩く方が消費カロリーは多くなります。普段から、速く歩くことを意識してみてください。詳しくは<a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n",
      "equal": "歩数スコアは10月と同じ点数です。<br>\n歩くスピードを意識したことはありますか？同じ歩数でも速く歩く方が消費カロリーは多くなります。普段から、速く歩くことを意識してみてください。詳しくは<a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは10月より%d点上がりました。素晴らしい！<br>\nウォーキングやヨガなどの有酸素運動は、幸せを感じるセロトニンを分泌することから、メンタルヘルスの向上や生活の質の改善にも効果があるといわれます。体力向上だけでなく、心のためにも体を動かしませんか？\n\n",
      "down": "運動のスコアは10月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\nウォーキングやヨガなどの有酸素運動は、幸せを感じるセロトニンを分泌することから、メンタルヘルスの向上や生活の質の改善にも効果があるといわれます。体力向上だけでなく、心のためにも体を動かしませんか？\n\n",
      "equal": "運動のスコアは10月と同じ点数です。<br>\nウォーキングやヨガなどの有酸素運動は、幸せを感じるセロトニンを分泌することから、メンタルヘルスの向上や生活の質の改善にも効果があるといわれます。体力向上だけでなく、心のためにも体を動かしませんか？\n\n"
    },
    "meal": {
      "up": "食事のスコアは10月より%d点上がりました。<br>\n毎日朝食を食べていますか？朝食は脳と体の目覚まし時計！1日のスタートを切るために欠かせないものです。時間がない朝に備えて、前日の残り物や、調理が必要ないゆで卵やバナナ・ヨーグルトなどを準備しておくのがおすすめです。\n\n",
      "down": "食事のスコアは10月より%d点下がりました。<br>\n毎日朝食を食べていますか？朝食は脳と体の目覚まし時計！1日のスタートを切るために欠かせないものです。時間がない朝に備えて、前日の残り物や、調理が必要ないゆで卵やバナナ・ヨーグルトなどを準備しておくのがおすすめです。\n\n",
      "equal": "食事のスコアは10月と同じ点数です。<br>\n毎日朝食を食べていますか？朝食は脳と体の目覚まし時計！1日のスタートを切るために欠かせないものです。時間がない朝に備えて、前日の残り物や、調理が必要ないゆで卵やバナナ・ヨーグルトなどを準備しておくのがおすすめです。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは10月より%d点上がりました。<br>\n酔いやすいかどうかは遺伝以外にも体重・体格・性別・年齢・その日の体調など様々な要因があります。無理な飲み方をせずに楽しく味わえる範囲で飲みましょう。お酒の適量については<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "down": "飲酒のスコアは10月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\n酔いやすいかどうかは遺伝以外にも体重・体格・性別・年齢・その日の体調など様々な要因があります。無理な飲み方をせずに楽しく味わえる範囲で飲みましょう。お酒の適量については<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "equal": "飲酒のスコアは10月と同じ点数です。<br>\n酔いやすいかどうかは遺伝以外にも体重・体格・性別・年齢・その日の体調など様々な要因があります。無理な飲み方をせずに楽しく味わえる範囲で飲みましょう。お酒の適量については<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは10月より%d点上がりました。<br>\n質の高い睡眠をとるには体から熱を放出することも大切。寒いからと厚着をしたり、靴下を履いて寝ると、睡眠の質の低下につながります。着込んで寝るより、部屋全体や寝具を温めるようにしましょう。\n\n",
      "down": "睡眠のスコアは10月より%d点下がりました。<br>\n質の高い睡眠をとるには体から熱を放出することも大切。寒いからと厚着をしたり、靴下を履いて寝ると、睡眠の質の低下につながります。着込んで寝るより、部屋全体や寝具を温めるようにしましょう。\n\n",
      "equal": "睡眠のスコアは10月と同じ点数です。<br>\n質の高い睡眠をとるには体から熱を放出することも大切。寒いからと厚着をしたり、靴下を履いて寝ると、睡眠の質の低下につながります。着込んで寝るより、部屋全体や寝具を温めるようにしましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは10月より%d点上がりました。<br>\nあなたのストレス解消法は何ですか？ストレス解消のための気分転換は質より量！映画を見る、カラオケに行く、友人と話すなど、あればあるほど良いものです。<a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">こちら</a>も参考にしてみてください。\n\n",
      "down": "ストレスのスコアは10月より%d点下がりました。<br>\nあなたのストレス解消法は何ですか？ストレス解消のための気分転換は質より量！映画を見る、カラオケに行く、友人と話すなど、あればあるほど良いものです。<a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">こちら</a>も参考にしてみてください。\n\n",
      "equal": "ストレスのスコアは10月と同じ点数です。<br>\nあなたのストレス解消法は何ですか？ストレス解消のための気分転換は質より量！映画を見る、カラオケに行く、友人と話すなど、あればあるほど良いものです。<a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">こちら</a>も参考にしてみてください。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since October. Which items have you seen change?<br>\nWith the year almost over, December is a time of many events such as Christmas and year-end parties, and it is easy to gain weight.\r\nIn order to notice changes in your body as soon as possible, be sure to weigh yourself frequently.\r\nIt is recommended that you weigh yourself at a set time every day.\n\n",
      "down": "Your lifestyle score has gone down by %d points since October.  Which items have you seen change?<br>\nWith the year almost over, December is a time of many events such as Christmas and year-end parties, and it is easy to gain weight.\r\nIn order to notice changes in your body as soon as possible, be sure to weigh yourself frequently.\r\nIt is recommended that you weigh yourself at a set time every day.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in October. Excellent!<br>\nWith the year almost over, December is a time of many events such as Christmas and year-end parties, and it is easy to gain weight.\r\nIn order to notice changes in your body as soon as possible, be sure to weigh yourself frequently.\r\nIt is recommended that you weigh yourself at a set time every day.\n\n",
      "equal": "Your lifestyle score is the same as it was in October.<br>\nWith the year almost over, December is a time of many events such as Christmas and year-end parties, and it is easy to gain weight.\r\nIn order to notice changes in your body as soon as possible, be sure to weigh yourself frequently.\r\nIt is recommended that you weigh yourself at a set time every day.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since October. Keep up the good work!<br>\nHave you ever considered how fast you walk? You will burn more calories if you walk faster for the same number of steps.\r\nTry to walk faster on a regular basis. For more information, <a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">click here</a>.\n\n",
      "down": "Your steps score has gone down %d points since October. <br>\nHave you ever considered how fast you walk? You will burn more calories if you walk faster for the same number of steps.\r\nTry to walk faster on a regular basis. For more information, <a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">click here</a>.\n\n",
      "equal": "Your steps score is the same as it was in October. <br>\nHave you ever considered how fast you walk? You will burn more calories if you walk faster for the same number of steps.\r\nTry to walk faster on a regular basis. For more information, <a href=\"https://go.andwell.jp/library/tag/9ccda7b8-5e2f-11ec-9728-06d158a16ffc\">click here</a>.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since October. Excellent!<br>\nAerobic exercise, such as walking and yoga, releases serotonin, which makes us feel happy, and is said to improve mental health and quality of life.\r\nWhy not move your body not only to improve your physical fitness, but also for your mind?\n\n",
      "down": "Your exercise score has gone down %d points since October. Let's take a look back at the last month.<br>\nAerobic exercise, such as walking and yoga, releases serotonin, which makes us feel happy, and is said to improve mental health and quality of life.\r\nWhy not move your body not only to improve your physical fitness, but also for your mind?\n\n",
      "equal": "Your exercise score is the same as it was in October. <br>\nAerobic exercise, such as walking and yoga, releases serotonin, which makes us feel happy, and is said to improve mental health and quality of life.\r\nWhy not move your body not only to improve your physical fitness, but also for your mind?\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since October.<br>\nDo you eat breakfast every day? Breakfast is an alarm clock for your brain and body! It is also a necessity to get your day started off right. To prepare for mornings when you are short on time, we recommend preparing leftovers from the previous day, or foods such as boiled eggs, bananas, yogurt, etc. that do not require cooking.\n\n",
      "down": "Your food score has gone down %d points since October.<br>\nDo you eat breakfast every day? Breakfast is an alarm clock for your brain and body! It is also a necessity to get your day started off right. To prepare for mornings when you are short on time, we recommend preparing leftovers from the previous day, or foods such as boiled eggs, bananas, yogurt, etc. that do not require cooking.\n\n",
      "equal": "Your food score is the same as it was in October. <br>\nDo you eat breakfast every day? Breakfast is an alarm clock for your brain and body! It is also a necessity to get your day started off right. To prepare for mornings when you are short on time, we recommend preparing leftovers from the previous day, or foods such as boiled eggs, bananas, yogurt, etc. that do not require cooking.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since October.<br>\nIn addition to genetics, there are many other factors that affect whether or not you are easily intoxicated, including weight, body size, gender, age, and physical condition on the day. Drink to the extent that you can enjoy yourself without overdoing it. <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> to learn more about the proper amount of alcohol to drink.\n\n",
      "down": "Your alcohol score has gone down %d points since October. Be careful not to drink too much.<br>\nIn addition to genetics, there are many other factors that affect whether or not you are easily intoxicated, including weight, body size, gender, age, and physical condition on the day. Drink to the extent that you can enjoy yourself without overdoing it. <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> to learn more about the proper amount of alcohol to drink.\n\n",
      "equal": "Your alcohol score is the same as it was in October. <br>\nIn addition to genetics, there are many other factors that affect whether or not you are easily intoxicated, including weight, body size, gender, age, and physical condition on the day. Drink to the extent that you can enjoy yourself without overdoing it. <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> to learn more about the proper amount of alcohol to drink.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since October.<br>\nReleasing heat from the body is also important for quality sleep. Sleeping in heavy clothing or socks because of the cold can lead to poor sleep quality. Instead of wearing too much clothing to bed, try to warm up the entire room and bedding.\n\n",
      "down": "Your sleep score has gone down %d points since October.<br>\nReleasing heat from the body is also important for quality sleep. Sleeping in heavy clothing or socks because of the cold can lead to poor sleep quality. Instead of wearing too much clothing to bed, try to warm up the entire room and bedding.\n\n",
      "equal": "Your sleep score is the same as it was in October. <br>\nReleasing heat from the body is also important for quality sleep. Sleeping in heavy clothing or socks because of the cold can lead to poor sleep quality. Instead of wearing too much clothing to bed, try to warm up the entire room and bedding.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since October.<br>\nWhat is your stress reliever? A change of pace to relieve stress is about quantity over quality! The more you have, the better: watch a movie, go to karaoke, talk with friends, etc. Please refer to <a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">this page</a> as well.\n\n",
      "down": "Your stress score has gone down %d points since October.<br>\nWhat is your stress reliever? A change of pace to relieve stress is about quantity over quality! The more you have, the better: watch a movie, go to karaoke, talk with friends, etc. Please refer to <a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">this page</a> as well.\n\n",
      "equal": "Your stress score is the same as it was in October. <br>\nWhat is your stress reliever? A change of pace to relieve stress is about quantity over quality! The more you have, the better: watch a movie, go to karaoke, talk with friends, etc. Please refer to <a href=\"https://go.andwell.jp/library/tag/9b7c4883-5e2f-11ec-9728-06d158a16ffc\">this page</a> as well.\n\n"
    }
  }
}